





































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import moment from 'moment';
import _, { isString } from 'lodash';
import qs from 'qs';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import {
  adminHost,
  getTodayDate,
  handleNewsUrl,
  handleNodeServerError,
} from '~/util/common';
import GoodsItem from '~/components/Goods/item-seo.vue';
import { TraceCode } from '~/util/trace';

export default Vue.extend({
  filters: {
    sectionTitle1(tags: Record<string, any>[], suffixText: string) {
      const secondIndustry = tags.find(
        (item) => item.type === 'secondIndustry'
      );

      if (secondIndustry?.name) {
        return `${secondIndustry.name}${suffixText}`;
      }
      return suffixText;
    },
    sectionTitle(tags: Record<string, any>[], suffixText: string, area: any) {
      let result = '';
      const secondIndustry = tags.find(
        (item) => item.type === 'secondIndustry'
      );

      if (secondIndustry?.name) {
        result = `${secondIndustry.name}${suffixText}`;
      } else {
        result = '展会资讯';
      }
      // 地区名称(地区名称+城市名称)
      let areaListRes = tags.filter((item) => item.type === 'province');
      let areaName: string = '';
      if (areaListRes.length > 0) {
        areaListRes.map((i: any) => {
          areaName +=
            i.name.substr(-1) == '市'
              ? i.name.substr(0, i.name.length - 1)
              : i.name;
        });
      }
      // if (areaName == '') areaName = area == 0 ? '国际' : '国内';
      return areaName + result || suffixText;
    },
    description(item: Record<string, any>): any {
      const content = item.description || item.content || '';
      const reg = /<[^>]+>/gi;
      return content
        .replace(reg, '')
        .replace(/&nbsp;/g, '')
        .trim()
        .slice(0, 100);
    },

    brandName(tags: any[]): string {
      const obj: Record<string, any> = {};
      tags.forEach((tag) => {
        obj[tag.type] = tag.name;
      });

      let name = '';

      if (obj.date) {
        name += obj.date;
      }

      if (obj.province) {
        name += obj.province;
      } else if (obj.area) {
        name += obj.area;
      }

      if (obj.secondIndustry) {
        name += obj.secondIndustry;
      } else if (obj.firstIndustry) {
        name += obj.firstIndustry;
      }

      return `${name}`;
    },
    firstLink(val: string): string {
      const [firstIndustryId, secondIndustryId, area, province, city, date] =
        val.split('-').slice(1, 7);

      if (
        firstIndustryId === '0' &&
        secondIndustryId === '0' &&
        area === '0' &&
        province === '0' &&
        city === '0' &&
        date === '0'
      ) {
        return '/exhibition/';
      } else {
        return val;
      }
    },
  },
  components: { GoodsItem },
  // 校验params参数
  validate(ctx: Context) {
    const { path } = ctx.route;
    const { keyword } = ctx.query;
    if (isString(keyword)) {
      return true;
      // 搜索词不能包含 网址、網纸；只能输入字母和汉字、搜索词长度必须小于等于 50
      // return (
      //   !/网址|網纸/.test(keyword) &&
      //   /^[a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword) &&
      //   keyword.length <= 50
      // );
    } else {
      if (
        path === '/exhibition' ||
        path === '/exhibition/' ||
        path.includes('/exhibition')
      ) {
        return true;
      }
      const _validate =
        /exhibition-(\d+)-(\d+)-(\d+)-(\d+)-(\d+)-(\d+)-(\d+)/.test(path);

      return _validate;
    }
  },
  async asyncData(ctx) {
    const lang = ctx.query.lang;
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    try {
      const { keyword, sort, type: sortType } = ctx.query;
      let apiErrorList: any = [];
      let {
        f: firstIndustryId = '0',
        s: secondIndustryId = 0,
        g: area = 0,
        p: province = 0,
        c: city = 0,
        t: date = '0',
        page = 1,
      } = ctx.params;
      const pageNum = isNaN(+page) ? 1 : +page;
      const pageSize = 20;
      let isRecommond = false; // 是否显示行业推荐, 默认不显示
      const currentYear: number = new Date().getFullYear(); // 当前年份
      const currentMonth: number = new Date().getMonth(); // 当前月份
      let dateYearMonth: string = ''; // 年月 格式:202409

      let type = '0'; // 国内
      if (+area === 1) {
        type = '0';
      } else {
        type = '1';
      }

      let countList: any = [];
      let hotCityList: any = [];

      if (+firstIndustryId > 0 || +secondIndustryId > 0) {
        let params: any = {
          locale: langApi,
          regionType: type,
        };
        if (+firstIndustryId > 0) params['industryId'] = firstIndustryId;
        if (+secondIndustryId > 0) params['industryId'] = secondIndustryId;
        const data: any = await ctx.$axios.post(
          '/api/exhibition/dimension',
          params
        );
        if (data.code == 1) {
          countList = data.data;
        }
        console.log('1021-', countList);

        // const cachedData = ctx.app.$cache.get(
        //   `${firstIndustryId}_${secondIndustryId}_${area}_${province}_${city}_${date}`
        // ); // myData

        // if (cachedData) {
        //   // 如果缓存数据存在，直接返回缓存数据
        //   countList = cachedData.data;
        //   // return { data: cachedData };
        // } else {
        //   // 如果缓存数据不存在，获取新数据并存储到缓存中
        //   let params: any = {
        //     locale: langApi,
        //     regionType: type,
        //   };
        //   if (+firstIndustryId > 0) params['industryId'] = firstIndustryId;
        //   if (+secondIndustryId > 0) params['industryId'] = secondIndustryId;
        //   const data: any = await ctx.$axios.post(
        //     '/api/exhibition/dimension',
        //     params
        //   );

        //   if (data.code == 1) {
        //     ctx.app.$cache.set(
        //       `${firstIndustryId}_${secondIndustryId}_${area}_${province}_${city}_${date}`,
        //       data
        //     ); // myData
        //     let cachedDataRes = ctx.app.$cache.get(
        //       `${firstIndustryId}_${secondIndustryId}_${area}_${province}_${city}_${date}`
        //     ); // myData

        //     countList = data.data;
        //   }
        // }
      }

      // 检查地区下是否有展会
      // let countResult: any = await ctx.$axios.post(
      //   '/api/exhibition/dimension',
      //   {
      //     locale: langApi,
      //   }
      // ); // 检查当前筛选条件下是否有展会(按行业id, areaId, cityId查询)

      // 排序
      const sortObj: Record<string, any> = {};
      if (sort === 'scale' && sortType) {
        sortObj.sortScale = sortType;
      } else if (sort === 'hot' && sortType) {
        sortObj.sortHot = sortType;
      } else if (sort === 'start_time' && sortType) {
        sortObj.sortTime = sortType;
      }
      // 搜索列表
      let listParams: Record<string, any> = {
        locale: langApi,
        pageNum,
        pageSize,
        regionType: type,
        selectTimeType: 1,
        // regionId: 0,
        // sortStr: sort,
        // sortType,
        // time: moment().format('YYYY-MM-DD'),
      };
      listParams = Object.assign(listParams, sortObj);

      // 行业推荐
      let hotParams: Record<string, any> = {
        locale: langApi,
        pageNum: 1,
        pageSize: 8,
        regionType: type,
        recommond: '2',
        selectTimeType: 1, // 0:查询当月的 1:查询给定时间之后的
      };
      hotParams = Object.assign(hotParams, sortObj);

      let requestList: any[] = [];

      if (+firstIndustryId > 0) {
        listParams.industryId = firstIndustryId || 0;
        ctx.store.dispatch('global/getLinks', { id: firstIndustryId });
      }

      if (+secondIndustryId > 0) {
        isRecommond = true; // 二级行业有值, 显示行业推荐
        listParams.industryId = secondIndustryId || 0;
        hotParams.industryId = secondIndustryId || 0;

        requestList = [
          ctx.$axios.post('/api/exhibition/list', hotParams), // 行业推荐
          ctx.$axios.post('/api/newsApi/homePageList', {
            industryId: secondIndustryId,
            pageNum: 1,
            pageSize: 7,
          }), // 推荐资讯
        ];
      }

      if (+area > 1) {
        listParams['regionId'] = +area;
      }

      if (province > 0) {
        listParams.area = province;
      }
      if (city > 0) {
        listParams['cityId'] = city;
      }

      if (+date > 0) {
        if (date.length == 6) {
          date = date.slice(4); // 获取最后两位
        }
        // 日期格式错误 则算作当前月份 如：超出6位,
        if (
          date.length > 6 ||
          Number(date) < 1 ||
          Number(date) > 12 ||
          !Number(date)
        ) {
          date = String(currentMonth + 1);
        }

        if (Number(date) < currentMonth + 1) {
          dateYearMonth = `${currentYear + 1}${
            Number(date) < 10 ? '0' + Number(date) : date
          }`;
        } else {
          dateYearMonth = `${currentYear}${
            Number(date) < 10 ? '0' + Number(date) : date
          }`;
        }
        const dateTemp = `${dateYearMonth.slice(0, 4)}-${dateYearMonth.slice(
          4
        )}-01`;
        listParams.time = moment(new Date(dateTemp)).format('YYYY-MM-DD');
        if (listParams.time === 'Invalid date') {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
      }

      let queryListByKeyWordParams = {};
      if (keyword) {
        queryListByKeyWordParams = {
          pageNum,
          pageSize: 20,
          keyWords: keyword,
        };
        Object.assign(queryListByKeyWordParams, sortObj);
      }

      let areaType: any = ' ';
      if (area === '1') {
        areaType = 0;
      } else {
        areaType = 1;
      }

      requestList = [
        ctx.$axios.post('/api/bannerApi/page', {
          type: 4,
          channel: 0,
          industry1: firstIndustryId,
          industry2: secondIndustryId,
          areaType,
        }), // 首页Banner
        ctx.$axios.post('/api/newsApi/homePageList', {
          newsType: 1,
          pageNum: 1,
          pageSize: 15,
        }),
        ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`),
        ctx.$axios.get(`/api/exhibition/city/${type}`),
        keyword
          ? ctx.$axios.post(
              '/api/exhibition/queryListByKeyWord',
              queryListByKeyWordParams
            )
          : ctx.$axios.post('/api/exhibition/list', listParams),

        ...requestList,
      ];

      const result: any[] = await Promise.all(requestList);
      const [
        bannerRes,
        newsListRes,
        industryRes,
        areaRes,
        fairRes,
        hotFairRes,
        newsRes,
      ] = result;

      let newsList = [];
      if (newsListRes.code === ResponseCode.SUCCESS) {
        newsList = newsListRes.data.records.map(
          (item: {
            newsId: any;
            viewUrl: any;
            newsName: any;
            publishTime: any;
          }) => {
            const isToday = moment().diff(moment(item.publishTime), 'days');
            const format = 'HH:mm';

            return {
              newsId: item.newsId,
              viewUrl: item.viewUrl,
              newsName: item.newsName,
              createTime: moment(item.publishTime).format(format),
            };
          }
        );
      }

      const banner = [];
      if (bannerRes?.code === ResponseCode.SUCCESS) {
        for (let i = 0; i < bannerRes.data.length; i++) {
          const url = bannerRes.data[i].bannerUrlList;
          const link = bannerRes.data[i].bannerLinkListPc;
          banner.push({
            url,
            link,
          });
        }
      }
      let industry = [];
      if (industryRes?.code === ResponseCode.SUCCESS) {
        industry = industryRes.data;
      }

      let areas = [];
      if (areaRes?.code === ResponseCode.SUCCESS) {
        areas = areaRes.data;

        areas.map((i: any) => {
          i['hasData'] = true;
          // i['hasData'] = countList.some(
          //     (x: any) =>
          //       x.region == i.area &&
          //       checkIndustry(x.industryId) &&
          //       checkDate(x.startTime)
          //   ) || area == i.area
          //     ? true
          //     : false; // true有数据, false无数据; area == item.area 显示当前选中的洲

          if (i.areas) {
            i.areas.map((j: any) => {
              j['hasData'] = true;
            });
            //
            // 按exhibitionCount(展会数量)排序
            i.areas.sort(
              (a: any, b: any) => b.exhibitionCount - a.exhibitionCount
            );
          }
        });
      }

      let fair: any = {
        records: [],
      };

      if (fairRes?.code === ResponseCode.SUCCESS) {
        fair = fairRes.data;

        if (pageNum > 1 && pageNum > fairRes.data.pages) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
      } else {
        // apiErrorList.push({ code: fairRes.code, message: fairRes.message });
        if (fairRes.code == 20002) {
          console.log('关键词格式错误:', keyword);
        } else {
          apiErrorList.push({
            api: keyword
              ? '/api/exhibition/queryListByKeyWord'
              : '/api/exhibition/list',
            code: fairRes.code,
            params: keyword ? queryListByKeyWordParams : listParams,
            message: fairRes.message,
          });
          handleNodeServerError(ctx, JSON.stringify(apiErrorList));
        }
      }
      let otherRequestList: any[] = [];
      let otherFairList: any = []; // 同行业的其它展会, 查询5条
      if (fair.records.length <= 1) {
        let otherParams: any = {
          industryId: secondIndustryId || '',
          pageNum: 1,
          pageSize: 5,
          regionType: type,
          selectTimeType: 1,
          sortTime: 'asc',
        };
        // 如果已选择二级行业,则不加地区去查询; 如果没选二级行业,才能用地区去查询
        if (province && secondIndustryId == 0) otherParams['area'] = province;
        // if(city) otherParams["cityId"] = city;
        otherRequestList = [
          ctx.$axios.post('/api/exhibition/list', otherParams),
        ];

        const result: any[] = await Promise.all(otherRequestList);
        const [otherFairRes] = result;
        if (otherFairRes?.code === ResponseCode.SUCCESS) {
          otherFairList = otherFairRes.data.records;
        }
      }

      let hotFair = {};
      if (hotFairRes?.code === ResponseCode.SUCCESS) {
        hotFair = hotFairRes.data;
      }

      const news = [];
      if (newsRes?.code === ResponseCode.SUCCESS && newsRes.data && newsRes.data.records) {
        for (let index = 0; index < newsRes.data.records.length; index++) {
          const item = newsRes.data.records[index];
          const description =
            index === 0
              ? await ctx.$axios.get(`/ossApi/newsContent/${item.newsId}.html`)
              : '';

          news.push({
            newsId: item.newsId,
            viewUrl: item.viewUrl,
            newsName: item.newsName,
            logo: item.logo,
            industryName: item.industryName,
            industryId: item.industryId,
            createTime: moment(item.publishTime).format('MM-DD HH:mm:ss'),
            views: item.views,
            description: typeof description == 'string' ? description : '',
            specialTopics: item.specialTopics,
          });
        }
      }

      let dateList: any[] = [];

      for (let i = 0; i < 12; i++) {
        const date = moment().set({
          year: currentYear,
          month: currentMonth + i,
        });
        dateList.push({
          label: date.format('YYYY年MM月'),
          value: date.format('M'), // YYYYMM
          value2: date.format('YYYY-MM'),
          hasData: true,
        });
      }

      // 检查筛选条件展会数据
      // 如果勾选了二级行业id,则检查二级行业
      let checkIndustry = (v: any) => {
        return +secondIndustryId > 0 ? v == secondIndustryId : true;
      };

      // 勾选时间
      let checkDate = (v: any) => {
        let timeStr =
          +date > 0
            ? dateYearMonth.slice(0, 4) + '-' + dateYearMonth.slice(4, 6)
            : '--';

        return +date > 0 ? v.includes(timeStr) : true;
      };

      if (+firstIndustryId > 0 || +secondIndustryId > 0) {
        dateList.map((dateItem: any) => {
          dateItem['hasData'] = countList.some(
            (i: any) =>
              i.startTime.includes(dateItem.value2) &&
              checkIndustry(i.industryId)
          )
            ? true
            : false; // true有数据, false无数据
        });
        dateList = dateList.filter((item) => item.hasData); // 只显示hasData为true的数据
      }

      // 已选条件
      let secondIndustryName: any = '';
      const checkTags = [];
      let secondIndustry = [];
      let cityList = [];
      if (+firstIndustryId > 0) {
        // 已选一级行业
        const item = industry.find(
          (item: any) => item.industryId == firstIndustryId
        );
        secondIndustry = item?.children; // 二级行业
        checkTags.push({
          name: item?.industryName,
          type: 'firstIndustry',
          id: firstIndustryId,
        });
      }
      if (secondIndustryId > 0) {
        // 已选二级行业
        const item = secondIndustry?.find(
          (item: any) => item.id == secondIndustryId
        );
        if (!item) {
          // 如果二级行业id不存在,则重定向为0
          let newUrl: any = `/exhibition-${firstIndustryId}-0-${area}-${province}-0-${date}-1`;
          ctx.redirect('301', newUrl);
          // return ctx.error({
          //   message: 'Page not found',
          //   path: ctx.route.fullPath,
          //   statusCode: 404,
          // });
        }
        secondIndustryName = item?.industryName;
        checkTags.push({
          name: item?.industryName,
          type: 'secondIndustry',
          id: secondIndustryId,
          pid: firstIndustryId,
        });
      }
      if (area > 0) {
        const item = areas.find((item: any) => item.id == area);
        if (!item) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
        cityList = item?.areas;
        // cityList.map((i: any) => (i['hasData'] = true));
        checkTags.push({
          name: item?.regionName,
          type: 'area',
          id: item?.id,
        });
      }
      let provinceCityList: any[] = []; // 当前选中省份的城市列表

      if (province > 0) {
        const item = cityList.find((item: any) => item.id == province);
        let cityRes: any = cityList.find((i: any) => province == i.id); // .areas || []; // 国外城市列表可能为空
        provinceCityList = cityRes && cityRes.areas ? cityRes.areas : [];
        // console.log('1459-', provinceCityList);

        provinceCityList.map((i: any) => {
          i['title'] =
            i.area.substr(-1) == '市'
              ? i.area.substr(0, i.area.length - 1)
              : i.area;
          i['hasData'] = true;
        });
        // 按exhibitionCount(展会数量)排序
        provinceCityList.sort(
          (a: any, b: any) => b.exhibitionCount - a.exhibitionCount
        );
        if (!item) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
        checkTags.push({
          name: item?.area,
          type: 'province',
          id: item?.id,
        });
      }
      if (city > 0) {
        const item = provinceCityList.find((item: any) => item.id == city);
        if (!item) {
          // 如果城市id不存在,将城市id重定向为0
          let newUrl: any = `/exhibition-${firstIndustryId}-${secondIndustryId}-${area}-${province}-0-${date}-1`;
          ctx.redirect('301', newUrl);

          // return ctx.error({
          //   message: 'Page not found-6',
          //   path: ctx.route.fullPath,
          //   statusCode: 404,
          // });
        }
        checkTags.push({
          name: item?.area,
          type: 'province',
          id: item?.id,
        });
      }
      if (+date > 0) {
        let dateM = date;
        if (String(date).length == 6) dateM = String(date).slice(4);

        // 已选时间
        const item = dateList.find((item: any) => item.value == Number(dateM));

        checkTags.push({
          name: item?.label || '',
          type: 'date',
          id: item?.value || date,
        });
      }

      if (keyword) {
        checkTags.push({
          name: keyword,
          type: 'keyword',
          id: keyword,
        });
      }
      // 展会时间 检查展会数量
      // dateList.map((dateItem: any) => {
      //   dateItem['hasData'] = countList.some(
      //     (i: any) =>
      //       i.startTime.includes(dateItem.value2) && checkIndustry(i.industryId)
      //   )
      //     ? true
      //     : false; // true有数据, false无数据
      // });

      // 地区(国内是省份) 检查展会数量
      if (+firstIndustryId > 0 || +secondIndustryId > 0) {
        cityList.map((item: any) => {
          item['hasData'] =
            countList.some(
              (i: any) =>
                i.areaId == item.id &&
                checkIndustry(i.industryId) &&
                checkDate(i.startTime)
            ) || province == item.id
              ? true
              : false; // true有数据, false无数据; province == item.id 显示当前选中的地区
        });
        // 国际-洲-国家-城市 检查是否有展会
        if (area == 0) {
          areas.map((item: any) => {
            item['hasData'] = countList.some(
              (i: any) =>
                i.region == item.area &&
                checkIndustry(i.industryId) &&
                checkDate(i.startTime)
            )
              ? true
              : false; // true有数据, false无数据; province == item.area 显示当前选中的地区
            // 国家

            item.areas.map((x: any) => {
              x['hasData'] = countList.some(
                (i: any) =>
                  i.areaId == x.id &&
                  checkIndustry(i.industryId) &&
                  checkDate(i.startTime)
              )
                ? true
                : false;
              // 城市
              if (x.areas && x.areas.length > 0) {
                x.areas.map((y: any) => {
                  y['hasData'] = countList.some(
                    (i: any) =>
                      i.areaId == y.id &&
                      checkIndustry(i.industryId) &&
                      checkDate(i.startTime)
                  )
                    ? true
                    : false;
                });
              }
            });
          });
        }

        // 按exhibitionCount(展会数量)排序
        // cityList.sort(
        //   (a: any, b: any) => b.exhibitionCount - a.exhibitionCount
        // );
        // 根据hasData排序,true排前面
        cityList.sort((a: any, b: any) => {
          if (a.hasData && !b.hasData) {
            return -1; // a在b前面
          } else if (!a.hasData && b.hasData) {
            return 1; // b在a前面
          }
          return 0; // 顺序不变
        });
        // 城市列表 检查展会数量

        provinceCityList.map((item: any) => {
          // hasData true有展会数据, false没有展会数据
          item['hasData'] =
            countList.some(
              (i: any) =>
                i.cityId == item.id &&
                checkIndustry(i.industryId) &&
                checkDate(i.startTime)
            ) || city == item.id
              ? true
              : false; // city == y.id 当前选中的城市要显示出来
        });
      }

      // 内链
      let innerChainActive: number = 0;
      let paramsIndustry: any[] = [];
      if (secondIndustryId) paramsIndustry.push(secondIndustryId);
      if (firstIndustryId) paramsIndustry.push(firstIndustryId);
      const innerChainAllRes: any = await Promise.all([
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门地区',
          pageSize: 16,
          // locale: detail.locale,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门行业',
          industryIdList: paramsIndustry,
          pageSize: 16,
          // locale: detail.locale,
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '热门展会',
          industryIdList: paramsIndustry,
          pageSize: 16,
          // locale: detail.locale,
        }),
      ]);

      const [innerChainAreaRes, innerChainIndustryRes, innerChainExhRes] =
        innerChainAllRes;
      let innerChainAreaList: any[] = [];
      let innerChainIndustryList: any[] = [];
      let innerChainExhList: any[] = [];

      if (innerChainAreaRes.code === ResponseCode.SUCCESS) {
        innerChainAreaList = innerChainAreaRes.data.records;
      }
      if (innerChainIndustryRes.code === ResponseCode.SUCCESS) {
        innerChainIndustryList = innerChainIndustryRes.data.records;
        let len = innerChainIndustryList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门行业',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainArea2Res] = ctxIndustry;
          if (innerChainArea2Res.code === ResponseCode.SUCCESS) {
            innerChainIndustryList = innerChainIndustryList.concat(
              innerChainArea2Res.data.records
            );
          }
        }
      }
      if (innerChainExhRes.code === ResponseCode.SUCCESS) {
        innerChainExhList = innerChainExhRes.data.records;
        let len = innerChainExhList.length;
        if (len < 16) {
          let ctxIndustry: any = await Promise.all([
            ctx.$axios.post('/api/innerChain/page', {
              category: '热门展会',
              pageSize: 16 - len,
            }),
          ]);

          const [innerChainExh2Res] = ctxIndustry;
          if (innerChainExh2Res.code === ResponseCode.SUCCESS) {
            innerChainExhList = innerChainExhList.concat(
              innerChainExh2Res.data.records
            );
          }
        }
      }

      let interfaceResponseTime = ctx.store.state.global.interfaceResponseTime;

      // 设置一个自定义响应头(nuxtTraceId 记录日志位置)
      if (ctx.res) ctx.res.setHeader('nuxtTraceId', interfaceResponseTime);
      if (ctx.$winstonLog) ctx.$winstonLog.info(interfaceResponseTime);
      ctx.store.commit('global/setInterfaceResponseTime', '');

      if (apiErrorList.length > 0)
        handleNodeServerError(ctx, JSON.stringify(apiErrorList));

      return {
        lang,
        langApi,
        updateTime: getTodayDate(),
        keyword,
        pageNum,
        pageSize,
        isRecommond,
        hotFair,
        fair,
        otherFairList,
        industry,
        secondIndustry,
        areaList: areas,
        cityList,
        provinceCityList,
        hotCityList,
        dateList,
        firstIndustryId,
        secondIndustryId: +secondIndustryId,
        secondIndustryName,
        area: +area,
        province: +province,
        city: +city,
        date,
        type: keyword ? -1 : type,
        checkTags,
        filter: listParams,
        news,
        sortType,
        banner,
        newsList,
        innerChainActive,
        innerChainAreaList,
        innerChainIndustryList,
        innerChainExhList,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data(): Record<string, any> {
    return {
      firstIndustryId: 0,
      secondIndustryId: 0,
      type: 0,
      area: 0,
      province: 0,
      city: 0,
      date: '0',
      typeList: [
        {
          label: '国内展会(含港澳台)',
          value: '0',
        },
        {
          label: '国际展会',
          value: '1',
        },
      ],
      value: '',
      fair: {},
      industry: [],
      secondIndustry: [],
      areaList: [],
      cityList: [],
      dateList: [],
      checkTags: [], //  已选条件
      moreArea: false, // 地区更多
      moreDate: false, // 时间更多
      activeSort: this.$route.query.sort,
      scale: {
        sortStr: 'scale',
        sortType: 'asc',
      },
      hot: {
        sortStr: 'hot',
        sortType: 'asc',
      },
      start_time: {
        sortStr: 'start_time',
        sortType: 'asc',
      },
    };
  },
  computed: {
    areas(): any[] {
      if (this.moreArea) {
        if (this.area === 0) {
          return this.areaList;
        }
        return this.cityList;
      } else {
        if (this.area === 0) {
          return this.$data.areaList?.slice(0, 8) || [];
        }
        return this.cityList?.slice(0, 8) || [];
      }
    },
    // 城市列表
    computedProvinceCityList(): any[] {
      if (this.moreCity) {
        return this.provinceCityList;
      } else {
        return this.provinceCityList?.slice(0, 30) || [];
      }
    },
    dates() {
      if (this.moreDate) {
        return this.$data.dateList;
      } else {
        return this.$data.dateList.slice(0, 6);
      }
    },
    newUrl() {
      return (item: any) => {
        let { firstIndustryId, secondIndustryId, area, province, city, date } =
          this;

        switch (item.type) {
          case 'firstIndustry':
            firstIndustryId = 0;
            secondIndustryId = 0;
            break;
          case 'secondIndustry':
            secondIndustryId = 0;
            break;
          case 'area':
            province = 0;
            city = 0;
            if (area === 1) {
              area = 1;
            } else {
              area = 0;
            }
            break;
          case 'province':
            province = 0;
            city = 0;
            break;
          case 'city':
            city = 0;
            break;
          case 'date':
            date = 0;
            break;
          default:
            return '/exhibition/';
        }

        if (
          firstIndustryId == 0 &&
          secondIndustryId == 0 &&
          area == 0 &&
          province == 0 &&
          city == 0 &&
          date == 0 &&
          this.pageNum == 1
        ) {
          return '/exhibition/';
        }
        return `/exhibition-${firstIndustryId}-${secondIndustryId}-${area}-${province}-${city}-${date}-1/`;
      };
    },
    // (时间地区行业)如果列表没有数据时，推荐数据的标题
    getBrandName(): string {
      let tags = this.checkTags;
      const obj: Record<string, any> = {};
      tags.forEach((tag: any) => {
        if (!obj.hasOwnProperty(tag.type)) obj[tag.type] = tag.name; // obj添加属性前检查属性是否存在
      });

      let name = '';
      if (obj.date) {
        name += obj.date;
      }
      if (obj.province) {
        name += obj.province;
      } else if (obj.area) {
        name += obj.area;
      }
      if (obj.secondIndustry) {
        name += obj.secondIndustry;
      } else if (obj.firstIndustry) {
        name += obj.firstIndustry;
      }

      return `${name}`;
    },
    // 当前选中的二级行业名称
    getBrandNameIndustry(): string {
      let tags = this.checkTags;
      const obj: Record<string, any> = {};
      tags.forEach((tag: any) => {
        if (!obj.hasOwnProperty(tag.type) && tag.type != 'firstIndustry')
          obj[tag.type] = tag.name; // obj添加属性前检查属性是否存在,排除一级行业名称
      });
      let name = '';
      if (obj.secondIndustry) {
        name += obj.secondIndustry;
      } else if (obj.firstIndustry) {
        name += obj.firstIndustry;
      }
      return `${name}`;
    },
    // 当前选中的城市名称
    getBrandCityName(): string {
      // 国内只显示城市名称, 国外显示国家+城市
      let tags = this.checkTags;
      let areaType = 0; // 0国内,1国外

      const obj: Record<string, any> = {};
      let name = '';
      let provinceList: any = [];
      tags.forEach((tag: any, index: number, arr: any) => {
        if (tag.type == 'area' && tag.id > 1) areaType = 1;
        if (tag.type == 'province') {
          obj[tag.type] = tag.name;
          provinceList.push(tag.name);
        }
      });
      // 长度2说明选择了城市
      if (provinceList.length >= 2 && areaType == 1) {
        name += provinceList[0];
      }
      if (obj.province) {
        name += obj.province;
      } else if (obj.area) {
        name += obj.area;
      }
      return `${name}`;
    },
  },
  created(): void {
    if (this.$route.query.type === 'hot') {
      this.activeSort = 'hot';
    }
    // 本地
    // const {
    //     f: firstIndustryId = '0',
    //     s: secondIndustryId = 0,
    //     g: area = 0,
    //     p: province = 0,
    //     c: city = 0,
    //     t: date = '0',
    //     page = 1
    //   } = this.$route.params

    // // if (+firstIndustryId > 0) {
    // //     this.$store.dispatch('global/getLinks', { id: firstIndustryId })
    // //   }
    // this.getIndustry()
    // 本地end
  },
  mounted() {},
  methods: {
    handleNewsUrl,
    handleOnlineService() {
      let serviceEl: any = document.getElementById('k_s_ol_chatWinSm_imgDiv'); // ceshiidmap k_s_ol_chatWinSm
      serviceEl.click();
    },
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },
    // handleFirstIndustryChange(industryId: number) {
    //   const item: any = this.industry.find(
    //     (item: any) => item.industryId === industryId
    //   );
    //   this.secondIndustry = item.children;
    // },
    brandName(tags: any[]): string {
      const obj: Record<string, any> = {};
      let areaType = 0; // 0国内,1国外
      let provinceList: any = [];
      tags.forEach((tag) => {
        obj[tag.type] = tag.name;
        if (tag.type == 'province') {
          provinceList.push(tag.name);
        }
      });

      let name = '';
      if (obj.date) {
        name += obj.date;
      }
      // 长度2说明选择了城市
      if (provinceList.length >= 2 && this.type == 1) {
        name += provinceList[0];
      }
      if (obj.province) {
        name += obj.province;
      } else if (obj.area) {
        name += obj.area;
        // name += obj.area == '中国' ? '国内' : obj.area;
      }

      if (obj.secondIndustry) {
        name += obj.secondIndustry;
      } else if (obj.firstIndustry) {
        name += obj.firstIndustry;
      }

      return `${name}`;
    },
    /**
     * 获取全部行业
     */
    getIndustry() {
      this.$axios
        .get(`/api/industry/getAll?locale=${this.langApi}`)
        .then((res: any) => {
          if (res.code == 1) {
            this.industry = res.data;

            let params = this.$route.params;
            // let firstIndustryId = Number(params.f)
            if (this.firstIndustryId > 0) {
              // 已选一级行业
              const item = this.industry.find(
                (item: any) => item.industryId == this.firstIndustryId
              );
              this.secondIndustry = item?.children; // 二级行业
              this.checkTags.push({
                name: item?.industryName,
                type: 'firstIndustry',
                id: this.firstIndustryId,
              });
            }
            if (this.secondIndustryId > 0) {
              // 已选二级行业
              const item = this.secondIndustry?.find(
                (item: any) => item.id == this.secondIndustryId
              );
              if (!item) {
                this.$message.error('二级行业不存在');
                return;
              }
              this.checkTags.push({
                name: item?.industryName,
                type: 'secondIndustry',
                id: this.secondIndustryId,
                pid: this.firstIndustryId,
              });
            }
          }
        });
    },
    // 地址更多
    handleAreaMore() {
      this.moreArea = !this.moreArea;
    },
    // 时间更多
    handleDateMore() {
      this.moreDate = !this.moreDate;
    },
    // 获取展会列表
    handleGetFairList({ sortStr, sortType }: Record<string, any>) {
      const params: any = _.cloneDeep(this.$route.query);

      const sort = this.sortType === 'desc' ? 'asc' : 'desc';
      if (sortStr && sortType) {
        this.activeSort = sortStr;
        this[sortStr].sortType = sort;

        params.sort = sortStr;
        params.type = sort;
      }
      // window.location.href = `${this.$route.path}?${qs.stringify(params)}`
      // 重置页数为第1页
      window.location.href = `/exhibition-${this.firstIndustryId}-${
        this.secondIndustryId
      }-${this.area}-${this.province}-${this.city}-${
        this.date
      }-1/?${qs.stringify(params)}`;
      // this.$router.push(`${this.$route.path}?${qs.stringify(params)}`);

      // const res: any = await this.$axios.post('/api/exhibition/list', params);
      // if (res.code === ResponseCode.SUCCESS) {
      //   this.fair = res.data;
      // }
    },
    // 发布展会
    handleRelease() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push({
        //   path: `/login?redirect=${this.$route.fullPath}`,
        // });
        return;
      }
      window.open(`${adminHost}?token=${access_token}`, '_blank'); // TODO url: 填写主办方发布展会页面
    },
    changePager(page: number) {
      const { firstIndustryId, secondIndustryId, area, province, city, date } =
        this;

      this.$router.push({
        path: `/exhibition-${firstIndustryId}-${secondIndustryId}-${area}-${province}-${city}-${date}-${page}/`,
        query: this.$route.query,
      });
    },
    areaChange(value: number) {
      this.queryAreaList(value);
    },
    async queryAreaList(id: number) {
      if (+id > 1) {
        const area: any = this.$data.areaList.find(
          (item: any) => item.id === +id
        );
        this.$data.cityList = area.areas;
      } else if (+id === 1) {
        const res: any = await this.$axios.get('/api/exhibition/city/0');
        if (res.code === ResponseCode.SUCCESS) {
          this.$data.cityList = _.get(res, 'data[0].areas');
        }
      }
    },
  },
  head(): Record<string, any> {
    const {
      f: firstIndustryId,
      s: secondIndustryId,
      g: continentId,
      p: countryId,
      c: cityId,
      t: date,
      page,
    }: any = this.$route.params;
    const { keyword = '' } = this.$route.query;

    let title = '';
    let keywords = '';
    let description = '';

    let pageText = '';
    let _year = moment().year(); // 年份,格式:2024
    let _month = 0; // 月份,格式:10
    let _date = ''; // 年月,格式:2024年10月
    // const year = moment().year();
    const currentYear = new Date().getFullYear(); // 当前年份
    const currentMonth = new Date().getMonth(); // 当前月份
    let dateYearMonth = ''; // 年月 格式:202409
    let dateMonth: string = date;
    _year = currentYear;

    if (date > 0) {
      if (date.length == 6) {
        dateMonth = date.slice(4); // 获取最后两位
      }
      if (Number(dateMonth) < currentMonth + 1) {
        dateYearMonth = `${currentYear + 1}${
          Number(dateMonth) < 10 ? '0' + Number(dateMonth) : dateMonth
        }`;
      } else {
        dateYearMonth = `${currentYear}${
          Number(dateMonth) < 10 ? '0' + Number(dateMonth) : dateMonth
        }`;
      }
      _year = moment(dateYearMonth).year();
      _month = moment(dateYearMonth).month() + 1;
      _date = `${_year}年${_month}月`;
    }

    let firstIndustry: Record<string, any> = {}; // 一级行业
    let secondIndustry: Record<string, any> = {}; // 二级行业
    let continent: Record<string, any> = {}; // 洲,国
    let area: Record<string, any> = {}; // 省份(国内)/国家(国际)
    let city: Record<string, any> = {}; // 城市

    if (firstIndustryId > 0) {
      firstIndustry = this.$data.industry.find(
        (item: { industryId: string }) => item.industryId == firstIndustryId
      );
    }

    if (secondIndustryId > 0) {
      secondIndustry = firstIndustry.children.find(
        (item: { id: string }) => item.id == secondIndustryId
      );
    }

    if (continentId > 0) {
      continent = this.$data.areaList.find(
        (item: { id: string }) => item.id == continentId
      );
    }

    if (countryId > 0) {
      area = continent.areas.find(
        (item: { id: string }) => item.id == countryId
      );
    }

    //  area.hasOwnProperty('areas') 是为了容错原url, 搜索引擎会访问（cityId位置写的是日期）exhibition-39-178-0-0-202011-202407-2/
    if (cityId > 0 && area.hasOwnProperty('areas')) {
      city = area.areas.find((item: { id: string }) => item.id == cityId);
    }

    let cityName = '';
    if (city && city.area) {
      cityName =
        city.area.substr(-1) == '市'
          ? city.area.substr(0, city.area.length - 1)
          : city.area;
    }

    let areaName = ''; // 地区名称,国内是省份名称,国外是国家名称
    if (area && area.area) areaName = area.area;

    let address = cityName || area.area || continent.regionName || '';

    if (page > 1) {
      pageText = `_第${page}页`;
    }
    // 国内
    if (this.type == 0) {
      title = `国内展会_中国展会_中国博览会_中国交易会_${_year}年中国展览会信息${pageText}-聚展`;
      keywords = `国内展会,中国展会,中国会展,中国展会信息,${keyword}`;
      description = `聚展网为您提供${_year}最新最全的中国展,中国展会排期,中国展览会信息及资讯,展会门票,展位预订,博览会门票,展会时间,是您参展中国展会最好的展会服务平台`;
    } else {
      title = `国际展会_国际博览会_国际展览会信息服务平台${keyword}${pageText}-聚展`;
      keywords = `国际展会,国际展会信息,国外展会,国际展览会,国外展览会,${keyword}`;
      description =
        '聚展网为您提供最新国际展览会,国外展会,国际会展中心展会信息,为您提供参展选展最佳指导方案,国际展览会中心最新展会资讯';
    }
    // 地区
    // if (address) {
    //   title = `${address}展会_${_year}年${address}展览会排期_时间表_地点${pageText}-聚展`;
    //   keywords = `${address}展会,${address}展览会,${address}博览会,${address}交易会`;
    //   description = `聚展网提供${_year}最新最全的${address}展,${address}展会排期,${address}展览会信息及资讯,展会门票,展位预订,博览会门票,展会时间,是您参展${address}展会最好的展会服务平台`;
    // }
    // 地区(国内是省份,国外是国家) 20241029
    if (countryId > 0) {
      if (this.type == 0) {
        // 国内
        title = `${areaName}展会_${_year}年${areaName}展会时间表_排期表_展会一览表-聚展`;
        keywords = `${areaName}展会,${areaName}展会${_year}时间表，${areaName}展会大全一览表,${areaName}展会${_year}年排期`;
        description = `聚展网为您提供${_year}年${areaName}展会的详尽时间表和排期，让您轻松掌握所有即将举办的${areaName}展会动态。我们还提供门票预订、展位预订、展会会刊获取以及展商名录查询等会展服务，让您参展体验顺畅无忧。`;
      } else {
        // 国际
        title = `${areaName}展会_${_year}年${areaName}展会时间表_排期表_展会一览表-聚展`;
        keywords = `${areaName}展会,${areaName}展会${_year}时间表，${areaName}展会大全一览表,${areaName}展会${_year}年排期`;
        description = `聚展网为您提供${_year}年${areaName}展会的时间表和排期，让您轻松掌握即将举办的展览会信息。我们还提供${_year}年${areaName}展会的签证协办、门票购买、展位预订、展会会刊及展商名录等会展服务，确保您在${areaName}展会中的参展体验顺畅无忧。`;
      }
    }
    // 城市 20241029
    if (cityId > 0) {
      if (this.type == 0) {
        // 国内
        title = `${cityName}展会_${cityName}展会${_year}年时间表_排期表_展会一览表-聚展`;
        keywords = `${cityName}展会,${cityName}展会${_year}时间表，${cityName}展会大全一览表,${cityName}展会${_year}年排期`;
        description = `聚展网为您提供${_year}年${cityName}展会的时间表和排期，方便您一览所有即将到来的展览会信息。我们还提供${_year}年${cityName}展会的门票预订、展位预订、展会会刊及展商名录等会展服务，全面满足您的参展需求。`;
      } else {
        // 国际
        title = `${cityName}展会_${areaName}${cityName}展会${_year}年时间表_排期表_展会一览表-聚展`;
        keywords = `${cityName}展会,${areaName}${cityName}展会${_year}年时间表,${cityName}${_year}年展会排期,${_year}年${areaName}${cityName}展会大全一览表`;
        description = `聚展网为您提供${_year}年${areaName}${cityName}展会的时间表和排期，让您轻松掌握即将举办的展览会信息。我们还提供${_year}年${cityName}展会的签证协办、门票购买、展位预订、展会会刊及展商名录等会展服务，确保您在${areaName}展会中的参展体验顺畅无忧。`;
      }
    }
    // 时间
    if (date > 0) {
      const _address = this.type === 0 ? '中国' : '国际';
      const _prefix = `${_date}${_address}`;

      title = `${_prefix}展会信息_${_address}博览会_${_address}交易会${pageText}-聚展`;
      keywords = `${_prefix}展览会,${_prefix}展会时间,${_prefix}展会地点`;
      description = `聚展网为您提供${_prefix}展会信息,${_date}展会排期,展会门票,为您参展选展提供最佳指导方案`;
    }
    // 一级行业
    switch (firstIndustryId) {
      case '16': // 车／配件
        title = `${_date}${address}车展_汽配摩配展_电动车展_自行车展_改装车展_${keyword}${pageText}-聚展`;
        keywords = `${address}车博览会,${address}汽配摩配博览会,电动车博览会,自行车博览会,改装车博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '19': // 五金/建材
        title = `${_date}${address}五金展_建材展_暖通制冷展_地铺展_玻璃展${keyword}${pageText}-聚展`;
        keywords = `${address}五金博览会,${address}建材博览会,暖通制冷博览会,地铺博览会,玻璃博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '26': // 工业/机械
        title = `${_date}${address}工业展_工程机械展_机器人展_木工展_自动化展${keyword}${pageText}-聚展`;
        keywords = `${address}工业博览会,${address}工程机械博览会,机器人博览会,木工博览会,自动化博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '27': // 电子/电力
        title = `${_date}${address}电子展_电力展_照明展_光电展_灯光舞台展${keyword}${pageText}-聚展`;
        keywords = `${address}电子博览会,${address}电力博览会,照明博览会,光电博览会,灯光舞台博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '28': // 通信/网络
        title = `${_date}${address}通讯展_通信展_游戏展_智能识别展_互联网展${keyword}${pageText}-聚展`;
        keywords = `${address}通讯博览会,${address}通信博览会,游戏博览会,智能识别博览会,互联网博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '29': // 能源化工
        title = `${_date}${address}能源展_化工展_太阳能展_环保展_电池展${keyword}${pageText}-聚展`;
        keywords = `${address}能源博览会,${address}化工博览会,太阳能博览会,环保博览会,电池博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '30': // 交通航天
        title = `${_date}${address}交通展_航空航天展_运输物流展_船舶海事展${keyword}${pageText}-聚展`;
        keywords = `${address}交通博览会,${address}航空航天博览会,运输物流博览会,船舶海事博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '31': // 安防劳保
        title = `${_date}${address}安防展_劳保展_防务展_军警展_公共安全展${keyword}${pageText}-聚展`;
        keywords = `${address}安防博览会,${address}劳保博览会,防务博览会,军警博览会,公共安全博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '32': // 纺织服装
        title = `${_date}${address}纺织面料服装展_鞋包展_珠宝钟表展_箱包皮革展${keyword}${pageText}-聚展`;
        keywords = `${address}纺织面料服装博览会,${address}鞋包博览会,珠宝钟表博览会,箱包皮革博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '33': // 生物医疗
        title = `${_date}${address}生物制药展_医疗设备展_美容美发展_成人用品展${keyword}${pageText}-聚展`;
        keywords = `${address}生物制药博览会,${address}医疗设备博览会,美容美发博览会,成人用品博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '34': // 消费家电
        title = `${_date}${address}日用消费品展_礼品展_家具展_消费电子展${keyword}${pageText}-聚展`;
        keywords = `${address}日用消费品博览会,${address}礼品博览会,家具博览会,消费电子博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '35': // 旅游户外
        title = `${_date}${address}户外体育用品展_实验仪器展_旅游用品展_教育展${keyword}${pageText}-聚展`;
        keywords = `${address}户外体育用品博览会,${address}实验仪器博览会,旅游用品博览会,教育博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '36': // 广告影视
        title = `${_date}${address}印刷包装展_纸业展_游戏娱乐展_影视器材展${keyword}${pageText}-聚展`;
        keywords = `${address}印刷包装博览会,${address}纸业博览会,游戏娱乐博览会,影视器材博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '37': // 办公婴童
        title = `${_date}${address}办公文具展_婴童用品展_玩具展_宠物展_${keyword}${pageText}-聚展`;
        keywords = `${address}办公文具博览会,${address}婴童用品博览会,玩具博览会,宠物博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '38': // 农业牧业
        title = `${_date}${address}农业展_畜牧展_饲料展_园林园艺展_渔业展_${keyword}${pageText}-聚展`;
        keywords = `${address}农业博览会,${address}畜牧博览会,饲料博览会,园林园艺博览会,渔业博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
      case '39': // 食品生鲜
        title = `${_date}${address}食品饮料展_烟草展_酒类展_果蔬展_咖啡茶展_${keyword}${pageText}-聚展`;
        keywords = `${address}食品饮料博览会,${address}烟草博览会,酒类博览会,果蔬博览会,咖啡茶博览会,${keyword}`;
        description = `聚展网_提供${keywords}等行业展览会介绍和展会资讯,为您提供订展一站式服务,报道${_year}最新最全展览会资讯`;
        break;
    }
    // 二级行业 20241029
    if (secondIndustryId > 0) {
      let secondIndustryName = secondIndustry?.industryName; // 二级行业名称
      if (this.type == 0) {
        // 国内
        title = `${secondIndustryName}展会_${_year}年中国${secondIndustryName}展会时间表_排期表_展会一览表-聚展`;
        keywords = `${_year}年${secondIndustryName}展会,${secondIndustryName}展会${_year}年时间表,${secondIndustryName}展会${_year}年排期,${_year}年${secondIndustryName}展会大全一览表`;
        description = `聚展网为您提供${_year}年中国${secondIndustryName}展会的详尽时间表和排期，让您轻松掌握所有即将举办的${secondIndustryName}展会动态。我们还提供门票预订、展位预订、展会会刊获取以及展商名录查询等会展服务，让您参展体验顺畅无忧。`;
      } else {
        // 国际
        title = `${secondIndustryName}展会_${_year}年国际${secondIndustryName}展会时间表_排期表_展会一览表-聚展`;
        keywords = `${_year}年国际${secondIndustryName}展会,国际${secondIndustryName}展会${_year}年时间表,国际${secondIndustryName}${_year}年展会排期,${_year}年国际${secondIndustryName}展会大全一览表`;
        description = `聚展网为您提供${_year}年国际${secondIndustryName}展会的时间表和排期，让您轻松掌握即将举办的展览会信息。我们还提供${_year}年国际${secondIndustryName}展会的签证协办、门票购买、展位预订、展会会刊及展商名录等会展服务，确保您在国际${secondIndustryName}展会中的参展体验顺畅无忧。`;
      }
    }

    // 时间+二级行业 20241029
    if (date > 0 && secondIndustryId > 0) {
      let secondIndustryName = secondIndustry?.industryName;
      let desc = '';
      let internation = this.type == 0 ? '中国' : '国际';
      let internation2 = this.type == 0 ? '' : '国际';
      if (this.type == 0) {
        desc = `聚展网为您提供${_date}${internation}${secondIndustryName}展会的时间表和排期，方便您一览所有即将到来的展览会信息。我们还提供${_year}年${secondIndustryName}展会的门票预订、展位预订、展会会刊及展商名录等会展服务，全面满足您的参展需求。`;
      } else {
        desc = `聚展网为您提供${_date}${internation}${secondIndustryName}展会的时间表和排期，让您轻松掌握即将举办的展览会信息。我们还提供${_year}年${internation}${secondIndustryName}展会的签证协办、门票购买、展位预订、展会会刊及展商名录等会展服务，确保您在${internation}${secondIndustryName}展会中的参展体验顺畅无忧。`;
      }
      keywords = `${_date}${internation2}${secondIndustryName}展会,${internation2}${secondIndustryName}展会${_date}时间表,${internation2}${secondIndustryName}展会${_date}排期,${_date}${internation2}${secondIndustryName}展会大全一览表`;
      description = desc;
      switch (_month) {
        case 1:
          title = `${secondIndustryName}展会_${_date}${internation2}${secondIndustryName}展会时间表_排期表_展会一览表-聚展`;
          break;
        case 2:
          title = `${secondIndustryName}展会_${_date}${internation2}${secondIndustryName}展会时间表_最新展会排期一览表_地点 -聚展`;
          break;
        case 3:
          title = `${secondIndustryName}展会_${internation2}${secondIndustryName}展览会${_date}近期排期表_时间地点 -聚展`;
          break;
        case 4:
          title = `${secondIndustryName}${_date}展会排期表_${internation2}${secondIndustryName}展会时间表_一览表_地点 -聚展`;
          break;
        case 5:
          title = `${secondIndustryName}展会_${internation2}${secondIndustryName}展会${_date}时间表_排期表_最新信息-聚展`;
          break;
        case 6:
          title = `${secondIndustryName}展会_${internation2}${secondIndustryName}${_date}展会大全一览表_时间表_排期-聚展`;
          break;
        case 7:
          title = `${secondIndustryName}展会${_date}时间表_${internation2}${secondIndustryName}展览会会最新时间表_排期一览表-聚展`;
          break;
        case 8:
          title = `${secondIndustryName}展会${_date}排期表_${internation2}${secondIndustryName}展会近期时间表_地点_一览表 -聚展`;
          break;
        case 9:
          title = `${secondIndustryName}${_date}展会大全一览表_${internation2}${secondIndustryName}展会排期表_时间地点 -聚展`;
          break;
        case 10:
          title = `${_date}${secondIndustryName}展会时间表_${internation2}${secondIndustryName}展会排期表_展览会信息-聚展`;
          break;
        case 11:
          title = `${_date}${secondIndustryName}展会排期表_${internation2}${secondIndustryName}展会时间表_展览会一览表-聚展`;
          break;
        case 12:
          title = `${_date}${secondIndustryName}展会大全一览表_${internation2}${secondIndustryName}近期展会时间表 -聚展`;
          break;
        default:
      }
    }
    // 时间+地区 20241029
    if (date > 0 && address && +firstIndustryId === 0) {
      const _prefix = `${_date}${address}`;
      let prefixCityName = cityName || areaName; // 城市名称 || 地区名称
      let areaCityName = ''; // 城市名称(国内), 国家名称+城市名称(国际)
      if (this.type == 0) {
        areaCityName = cityName || areaName; // 城市名称 || 地区名称
      } else {
        areaCityName = `${areaName}${cityName}`;
      }
      let secondIndustryName = secondIndustry?.industryName;
      let desc = '';
      if (this.type == 0) {
        desc = `聚展网为您提供${_date}${areaCityName}展会的时间表和排期，方便您一览所有即将到来的展览会信息。我们还提供${_year}年${areaCityName}展会的门票预订、展位预订、展会会刊及展商名录等会展服务，全面满足您的参展需求。`;
      } else {
        desc = `聚展网为您提供${_date}${areaCityName}展会的时间表和排期，让您轻松掌握即将举办的展览会信息。我们还提供${_year}年${areaCityName}展会的签证协办、门票购买、展位预订、展会会刊及展商名录等会展服务，确保您在${areaName}展会中的参展体验顺畅无忧。`;
      }
      keywords = `${_date}${areaCityName}展会,${areaCityName}展会${_date}时间表,${areaCityName}${_date}展会排期,${_date}${areaCityName}展会大全一览表`;
      description = desc;
      switch (_month) {
        case 1:
          title = `${prefixCityName}展会_${_date}${areaCityName}展会时间表_排期表_展会一览表-聚展`;
          break;
        case 2:
          title = `${prefixCityName}展会_${_date}${areaCityName}展会时间表_最新展会排期一览表_地点 -聚展`;
          break;
        case 3:
          title = `${prefixCityName}展会_${areaCityName}展览会${_date}近期排期表_时间地点 -聚展`;
          break;
        case 4:
          title = `${prefixCityName}${_date}展会排期表_${areaCityName}展会时间表_一览表_地点 -聚展`;
          break;
        case 5:
          title = `${prefixCityName}展会_${areaCityName}展会${_date}时间表_排期表_最新信息 -聚展`;
          break;
        case 6:
          title = `${prefixCityName}展会_${areaCityName}${_date}展会大全一览表_时间表_排期-聚展`;
          break;
        case 7:
          title = `${prefixCityName}展会${_date}时间表_${areaCityName}展览会最新时间表_排期一览表 -聚展`;
          break;
        case 8:
          title = `${prefixCityName}展会${_date}排期表_${areaCityName}展览会近期时间表_地点_最新信息 -聚展`;
          break;
        case 9:
          title = `${prefixCityName}${_date}展会大全一览表_${areaCityName}展会排期表_时间地点 -聚展`;
          break;
        case 10:
          title = `${_date}${prefixCityName}展会时间表_${areaCityName}展会日程表_展览会信息-聚展`;
          break;
        case 11:
          title = `${_date}${prefixCityName}展会排期表_${areaCityName}展览会时间表_展览会一览表-聚展`;
          break;
        case 12:
          title = `${_date}${prefixCityName}展会大全一览表_${areaCityName}近期展会时间表 -聚展`;
          break;
        default:
      }
    }
    // 地区(国内省,国外是国家) + 二级行业 20241029-3
    if (countryId > 0 && secondIndustryId > 0) {
      let areaCityName = ''; // 城市名称(国内), 国家名称+城市名称(国际)
      if (this.type == 0) {
        areaCityName = cityName;
      } else {
        areaCityName = `${areaName}${cityName}`;
      }
      let secondIndustryName = secondIndustry?.industryName;
      let prefix_date = date > 0 ? `${_date}` : `${_year}年`;
      if (this.type == 0) {
        title = `${areaName}${secondIndustryName}展会_${prefix_date}${areaName}${secondIndustryName}展时间表_排期表_展会一览表 -聚展`;
        keywords = `${areaName}${secondIndustryName}展会,${areaName}${secondIndustryName}展${prefix_date}时间表,${areaName}${secondIndustryName}展${prefix_date}排期`;
        description = `聚展网为您提供${prefix_date}${areaName}${secondIndustryName}展会的详尽时间表和排期，让您轻松掌握所有即将举办的展会动态。我们还提供门票预订、展位预订、展会会刊获取以及展商名录查询等会展服务，让您参展体验顺畅无忧。`;
      } else {
        title = `${areaName}${secondIndustryName}展会_${prefix_date}${areaName}${secondIndustryName}展时间表_排期表_门票 -聚展`;
        keywords = `${areaName}${secondIndustryName}展会,${areaName}${secondIndustryName}展${prefix_date}时间表，${areaName}${secondIndustryName}展会${prefix_date}排期`;
        description = `聚展网为您提供${prefix_date}${areaName}${secondIndustryName}展会的时间表和排期，让您轻松掌握即将举办的展览会信息。我们还提供${prefix_date}${areaName}${secondIndustryName}展的签证协办、门票购买、展位预订、展会会刊及展商名录等会展服务，确保您在${areaName}展会中的参展体验顺畅无忧。`;
      }
    }
    // 城市 + 二级行业 20241029-4
    if (cityId > 0 && secondIndustryId > 0) {
      let areaCityName = ''; // 城市名称(国内), 国家名称+城市名称(国际)
      if (this.type == 0) {
        areaCityName = cityName;
      } else {
        areaCityName = `${areaName}${cityName}`;
      }
      let secondIndustryName = secondIndustry?.industryName;
      if (this.type == 0) {
        title = `${cityName}${secondIndustryName}展会_${cityName}${secondIndustryName}展${_year}年时间表_排期表_门票 -聚展`;
        keywords = `${cityName}${secondIndustryName}展会,${cityName}${secondIndustryName}展${_year}年时间表,${cityName}${secondIndustryName}展${_year}年排期`;
        description = `聚展网为您提供${_year}年${cityName}${secondIndustryName}展会的时间表和排期，方便您一览所有即将到来的展览会信息。我们还提供${_year}年${cityName}${secondIndustryName}展的门票预订、展位预订、展会会刊及展商名录等会展服务，全面满足您的参展需求。`;
      } else {
        title = `${cityName}${secondIndustryName}展会_${areaCityName}${secondIndustryName}展${_year}年时间表_排期表_门票 -聚展`;
        keywords = `${cityName}${secondIndustryName}展会,${areaCityName}${secondIndustryName}展${_year}年时间表,${areaCityName}${secondIndustryName}展${_year}年排期`;
        description = `聚展网为您提供${_year}年${areaCityName}${secondIndustryName}展展会的时间表和排期，让您轻松掌握即将举办的展览会信息。我们还提供${_year}年${cityName}${secondIndustryName}展的签证协办、门票购买、展位预订、展会会刊及展商名录等会展服务，确保您在${areaName}展会中的参展体验顺畅无忧。`;
      }
    }

    // 时间+地区+二级行业 20241029-2
    if (date > 0 && cityName && secondIndustryId > 0) {
      const _prefix = `${_date}${address}`;
      let areaCityName = ''; // 城市名称(国内), 国家名称+城市名称(国际)
      if (this.type == 0) {
        areaCityName = cityName;
      } else {
        areaCityName = `${areaName}${cityName}`;
      }
      let secondIndustryName = secondIndustry?.industryName;
      let desc = '';
      if (this.type == 0) {
        desc = `聚展网为您提供${_date}${cityName}${secondIndustryName}展会的时间表和排期，方便您一览所有即将到来的展览会信息。我们还提供${_year}年${cityName}${secondIndustryName}展的门票预订、展位预订、展会会刊及展商名录等会展服务，全面满足您的参展需求。`;
      } else {
        desc = `聚展网为您提供${_date}${areaCityName}${secondIndustryName}展会的时间表和排期，让您轻松掌握即将举办的展览会信息。我们还提供${_year}年${cityName}${secondIndustryName}展的签证协办、门票购买、展位预订、展会会刊及展商名录等会展服务，确保您在${areaName}展会中的参展体验顺畅无忧。`;
      }
      keywords = `${_date}${cityName}${secondIndustryName}展会,${areaCityName}${secondIndustryName}展${_date}时间表,${areaCityName}${secondIndustryName}展${_date}排期`;
      description = desc;
      switch (_month) {
        case 1:
          title = `${cityName}${secondIndustryName}展会_${_date}${areaCityName}${secondIndustryName}展时间表_排期表_门票 -聚展`;
          break;
        case 2:
          title = `${cityName}${secondIndustryName}_${_date}${areaCityName}${secondIndustryName}会时间表_门票_地点 -聚展`;
          break;
        case 3:
          title = `${cityName}${secondIndustryName}_${areaCityName}${secondIndustryName}览会${_date}排期_时间地点_门票 -聚展`;
          break;
        case 4:
          title = `${cityName}${secondIndustryName}${_date}展会排期表_${areaCityName}${secondIndustryName}展时间地点_门票 -聚展`;
          break;
        case 5:
          title = `${cityName}${secondIndustryName}展会_${areaCityName}${secondIndustryName}展${_date}时间表_排期表_门票 -聚展`;
          break;
        case 6:
          title = `${cityName}${secondIndustryName}展_${areaCityName}${_date}${secondIndustryName}展会时间地点_排期表_门票 -聚展`;
          break;
        case 7:
          title = `${cityName}${secondIndustryName}展会${_date}时间表_${areaCityName}${secondIndustryName}展览会会最新排期表_门票 -聚展`;
          break;
        case 8:
          title = `${cityName}${secondIndustryName}展会${_date}排期表_${areaCityName}${secondIndustryName}展会近期时间表_地点_门票 -聚展`;
          break;
        case 9:
          title = `${cityName}${_date}${secondIndustryName}展会时间地点_${areaCityName}${secondIndustryName}展排期表_门票 -聚展`;
          break;
        case 10:
          title = `${cityName}${_date}${secondIndustryName}展时间表_${areaCityName}${secondIndustryName}展会排期表_门票-聚展`;
          break;
        case 11:
          title = `${_date}${cityName}${secondIndustryName}展会排期表_${areaCityName}${secondIndustryName}展时间表_门票-聚展`;
          break;
        case 12:
          title = `${_date}${cityName}${secondIndustryName}展时间表_${areaCityName}${secondIndustryName}展会排期_地点_门票 -聚展`;
          break;
        default:
      }
    }
    // 右上角输入框搜索内容
    if (keyword) {
      title = `${keyword}${pageText}-聚展`;
      keywords = `${keyword},展览会,博览会,交易会,展会时间,展会地点,展会门票`;
      description = `聚展网为您提供${_year}最新最全的${keyword},${keyword}展会排期,${keyword}展览会信息及资讯,是您参展${keyword}展会最好的展会服务平台`;
    }

    return {
      title,
      meta: [
        {
          name: 'mobile-agent',
          'http-equiv': 'mobile-agent',
          content: `format=html5; url=https://m.jufair.com/exhibition-${firstIndustryId}-${secondIndustryId}-${continentId}-${countryId}-${cityId}-${date}-${page}`,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://www.jufair.com/exhibition-${firstIndustryId}-${secondIndustryId}-${continentId}-${countryId}-${cityId}-${_month}-1/`,
        },
      ],
    };
  },
});
